var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container",staticStyle:{"background-color":"#efeff1"}},[_c('list-toolbar',{attrs:{"with-pagination":true,"list-query":_vm.listQuery,"total":_vm.total,"has-selected":_vm.selected.length,"title":_vm.$t('internalMailList.title')},on:{"value-changed":_vm.onSearchChanged,"pagination":_vm.getList}},[_c('template',{slot:"select-actions"},[_c('el-button',{attrs:{"size":"mini","icon":"el-icon-download"},on:{"click":function($event){return _vm.handleCommand('download')}}},[_vm._v(" "+_vm._s(_vm.$t('actions.download'))+" ")]),_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['DELETE_EMAILS']),expression:"['DELETE_EMAILS']"}],attrs:{"size":"mini","icon":"el-icon-delete"},on:{"click":function($event){return _vm.handleCommand('delete')}}},[_vm._v(" "+_vm._s(_vm.$t('actions.delete'))+" ")])],1)],2),_c('div',{staticClass:"container-fluid"},[_c('el-row',{directives:[{name:"loading",rawName:"v-loading.lock",value:(_vm.listLoading),expression:"listLoading",modifiers:{"lock":true}}],staticClass:"bee-main-container",attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":24}},[_c('el-table',{staticClass:"bee-style-table",attrs:{"data":_vm.list,"border":"","fit":"","highlight-current-row":""},on:{"sort-change":_vm.handleSortChange,"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"width":"45","type":"selection"}}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('table.sender')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.getSender(row)))])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('table.subject')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.getSubject(row))}})]}}])}),_c('el-table-column',{attrs:{"align":"center","width":"600px","label":_vm.$t('table.recipients')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticStyle:{"max-height":"200px","overflow-y":"scroll"},domProps:{"innerHTML":_vm._s(_vm.getRecipients(row))}})]}}])}),_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('table.properties')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.getProperties(row))}})]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"dateCreated","label":_vm.$t('table.dateCreated'),"sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.parseTime(row.date_created)))])]}}])}),(_vm.hasPermission(['EDIT_EMAILS', 'READ_EMAILS', 'DELETE_EMAILS']))?_c('el-table-column',{attrs:{"align":"center","label":_vm.$t('table.actions'),"width":"130px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['EDIT_EMAILS', 'READ_EMAILS']),expression:"['EDIT_EMAILS', 'READ_EMAILS']"}],staticClass:"full-width",attrs:{"size":"mini"},on:{"click":function($event){return _vm.redirectToEdit(row.id)}}},[_vm._v(" "+_vm._s(_vm.$t('table.edit'))+" ")])],1),_c('div',{staticStyle:{"margin-top":"5px"}},[_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['READ_EMAILS']),expression:"['READ_EMAILS']"}],staticClass:"full-width",attrs:{"size":"mini"},on:{"click":function($event){return _vm.redirectToView(row.id)}}},[_vm._v(" "+_vm._s(_vm.$t('table.view'))+" ")])],1),_c('div',{staticStyle:{"margin-top":"5px"}},[_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['EDIT_EMAILS', 'READ_EMAILS']),expression:"['EDIT_EMAILS', 'READ_EMAILS']"}],staticClass:"full-width",attrs:{"size":"mini"},on:{"click":function($event){return _vm.sendItem(row.id)}}},[_vm._v(" "+_vm._s(_vm.$t('actions.send'))+" ")])],1),_c('div',{staticStyle:{"margin-top":"5px"}},[_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['DELETE_EMAILS']),expression:"['DELETE_EMAILS']"}],staticClass:"full-width",attrs:{"size":"mini"},on:{"click":function($event){return _vm.deleteItem(row.id)}}},[_vm._v(" "+_vm._s(_vm.$t('table.delete'))+" ")])],1)]}}],null,false,1749742215)}):_vm._e()],1)],1)],1),_c('pagination',{attrs:{"hide-on-single-page":true,"total":_vm.total,"page":_vm.listQuery.query.pagination.page,"limit":_vm.listQuery.query.pagination.page_size},on:{"update:page":function($event){return _vm.$set(_vm.listQuery.query.pagination, "page", $event)},"update:limit":function($event){return _vm.$set(_vm.listQuery.query.pagination, "page_size", $event)},"pagination":_vm.getList}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }