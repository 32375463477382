





























































































































































import { Component, Vue } from 'vue-property-decorator'
import {
  createFilter,
  getSortItems,
  prepareListFilters
} from '@/utils/filter'
import Pagination from '@/components/Pagination/index.vue'
import ListToolbar from '@/components/ListToolbar/index.vue'
import {
  debounce,
  parseTime,
  hasPermission,
  confirmDialog,
  successMsg,
  errorMsg, getTranslations
} from '@/utils'
import { AppModule, DeviceType } from '@/store/modules/app'
import {
  deleteEmailBulk,
  deleteEmail,
  getEmails,
  exportEmails, sendEmail
} from '@/api/emails'

@Component({
  name: 'InternalMailList',
  components: {
    Pagination,
    ListToolbar
  }
})

export default class extends Vue {
  private count = 0
  private total = 0
  private list = []
  private listLoading = true
  private search = ''
  private selected: any = []
  private listQuery = createFilter()
  private debounceMethod = debounce(this.getList)
  private parseTime = parseTime
  private hasPermission = hasPermission
  private searchFields = []

  created() {
    this.getList()
  }

  get isMobile() {
    return AppModule.device === DeviceType.Mobile
  }

  private async deleteItem(id: string) {
    try {
      const [data] = await confirmDialog('actions.apiDelete')
      if (!data) return
      await deleteEmail({ id })
      await successMsg('actions.apiDeleteSuccess')
      await this.getList()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private async sendItem(id: string) {
    try {
      const [data] = await confirmDialog('actions.apiSend')
      if (!data) return
      await sendEmail({ id })
      await successMsg('actions.apiSendSuccess')
      await this.getList()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private handleSelectionChange(selected = []) {
    this.selected = selected.map((item: any) => {
      return item.id
    })
  }

  private handleSortChange(params: any = {}) {
    if (this.listQuery?.query?.sort) {
      this.listQuery.query.sort = getSortItems(params)
    }
    this.getList()
  }

  private async deleteItemsBulk() {
    try {
      if (!this.selected.length) {
        return errorMsg('actions.selectItems')
      }
      const [data] = await confirmDialog('actions.apiDeleteBulk')
      if (!data) return
      await deleteEmailBulk(this.selected)
      await successMsg('actions.apiDeleteBulkSuccess')
      await this.getList()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private prepareFilters() {
    prepareListFilters({
      listQuery: this.listQuery,
      withDefaultSort: true,
      search: this.search,
      searchFields: this.searchFields,
      filters: []
    })
  }

  private getSender(row: any) {
    return getTranslations(row.user)
  }

  private getRecipients(row: any) {
    if (!row.meta?.to?.length) return ''
    return row.meta.to.map((item: any) => {
      return `<span class="el-tag">
        ${item.name} ${'[' + item.email + ']'}
      </span>`
    }).join('')
  }

  private getSubject(row: any) {
    if (row.meta?.subject) {
      return row.meta.subject
    }
    return ''
  }

  private getProperties(row: any) {
    if (!row.meta?.properties?.length) return ''
    return row.meta.properties.map((item: any) => {
      return item
    }).join(', ')
  }

  private redirectToEdit(id: any) {
    return this.$router.push('/internal-mail/edit/' + id)
  }

  private redirectToView(id: any) {
    return this.$router.push('/internal-mail/view/' + id)
  }

  private handleCommand(command: string) {
    switch (command) {
      case 'download': {
        exportEmails(this.list)
        break
      }
      case 'delete': {
        this.deleteItemsBulk()
        break
      }
    }
  }

  private async getList() {
    try {
      this.listLoading = true
      this.prepareFilters()
      const { data } = await getEmails(this.listQuery)
      this.list = data.collection || []
      this.total = data.pagination.total_items || 0
      this.count = data.pagination.total_items || 0
    } catch (err) {
      await errorMsg('api.serverError')
    } finally {
      this.listLoading = false
    }
  }

  onSearchChanged(search: string) {
    this.search = search
    if (this.listQuery.query?.pagination) {
      this.listQuery.query.pagination.page = 1
    }
    this.debounceMethod()
  }
}
