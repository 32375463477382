import request from '@/utils/request'
import { exportJson2Excel, prepareExcelHeaders } from '@/utils/excel'
import { formatJson } from '@/utils'

export const getEmails = (data: any) =>
  request({
    method: 'post',
    url: '/api/emails/getEmails',
    data
  })

export const getEmailsCount = (data: any) =>
  request({
    method: 'post',
    url: '/api/emails/getEmailsCount',
    data
  })

export const getEmail = (params: any) =>
  request({
    method: 'get',
    url: '/api/emails/getEmail',
    params
  })

export const saveEmail = (data: any) =>
  request({
    method: 'post',
    url: '/api/emails/saveEmail',
    data
  })

export const emailPreview = (data: any) =>
  request({
    method: 'post',
    url: '/api/emails/emailPreview',
    data
  })

export const saveEmailWithMedia = (formData: any) =>
  request.post('/api/emails/saveEmailWithMedia', formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

export const sendEmail = (params: any) =>
  request({
    method: 'get',
    url: '/api/emails/sendEmail',
    params
  })

export const sendSms = (data: any) =>
  request({
    method: 'post',
    url: '/api/emails/sendSms',
    data
  })

export const saveEmailBulk = (data: any) =>
  request({
    method: 'post',
    url: '/api/emails/saveEmailBulk',
    data
  })

export const deleteEmail = (params: any) =>
  request({
    method: 'get',
    url: '/api/emails/deleteEmail',
    params
  })

export const deleteEmailBulk = (data: any) =>
  request({
    method: 'post',
    url: '/api/emails/deleteEmailBulk',
    data
  })

export const exportEmails = (emails: any) => {
  const fields = [
    {
      key: 'notes'
    },
    {
      key: 'date_created',
      type: 'timestamp'
    }
  ]

  return exportJson2Excel(
    prepareExcelHeaders(fields),
    formatJson(fields, emails),
    'email-list'
  )
}
